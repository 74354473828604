export const auth_service = {
  isAuthenticated,
  logout,
  setUserData,
  getUserData,
  getRefreshToken,
  setRefreshToken,
  clearData,
  setUserPermission,
  getUserPermission,
  getPermission,
  getRole,
};


function isAuthenticated() {
  if (
    localStorage.getItem("currentUser") !== undefined &&
    localStorage.getItem("currentUser") !== null
  ) {
    return true;
  } else {
    return false;
  }
}

function clearData() {
  localStorage.removeItem("userData");
  localStorage.removeItem("refreshToken");
}

function setRefreshToken(refreshToken) {
  localStorage.setItem("refreshToken", refreshToken);
}

function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

function setUserPermission(data) {
  localStorage.setItem("permission", data);
}

function getUserPermission() {
  let data = localStorage.getItem("permission");
  return data;
}

function getUserData() {
  let data = JSON.parse(localStorage.getItem("currentUser"));
  return data;
}

function setUserData(data) {
  localStorage.setItem("currentUser", JSON.stringify(data));
}

function logout() {
  localStorage.removeItem("currentUser");
}

function getRole(role) {
  return getUserData().role.includes(role);
}

// function getPermission(permission) {
//   let permissions = getUserPermission().toLowerCase();
//   if (permissions == null || permission === '') {
//     return true;
//   }
//   return permissions.includes(permission.toLowerCase());
// }

function getPermission(permission1, permission2) {
  let permissions = getUserPermission().toLowerCase().split(',');
  if (permissions == null || permission1 === '') {
    return true;
  }
  return permission2 ? (permissions.includes(permission1.toLowerCase()) || permissions.includes(permission2.toLowerCase())) : permissions.includes(permission1.toLowerCase());
}
